import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import JSXStyle from 'styled-jsx/style';

import styles from './ResponsiveMode.desktop.module.css';
import { AppContext } from '../../../services/AppContext';
import {
	ResponsiveModeContext,
	formatTime,
	useInternalLink,
} from './ResponsiveModeContext';
import imgLogo from '../../../components/navbar/logo.png';
import { Icon } from '../../../components/Icon';
import { Article } from '../../../components/canvasflow/article/Article';
import useDebounce from '../../../hooks/use-debounce';
import { useBalanceText } from './ResponsiveMode.hooks';

import { Carousel } from './Carousel';

export const ResponsiveDesktopContext = React.createContext({
	togglePlayer: null,
	isPlayerOpen: false,
	setIsPlayerOpen: null,
});

export const ResponsiveModeDesktop = () => {
	const [swiper, setSwiper] = useState(null);
	let { setIsFontOpen, player } = useContext(ResponsiveModeContext);
	const { isPrinting, isIframeMode, theme, config } = useContext(AppContext);
	const { fontSizeOption } = config;
	const [isTocOpen, setIsTocOpen] = useState(false);
	const [isShareOpen, setIsShareOpen] = useState(false);
	const [isPlayerOpen, setIsPlayerOpen] = useState(false);
	let className = ['App', styles['issue']];
	if (!isTocOpen) {
		className.push(styles['toc-close']);
	}

	useEffect(() => {
		if (swiper) {
			console.log(`SWIPER IN DESKTOP`, swiper);
			try {
				swiper.update();
				swiper.init();
			} catch (e) {
				console.error(e);
			}
		}
	}, [isTocOpen]);

	useBalanceText();

	if (isPrinting) {
		className.push(styles['print-view'], 'print-view');
		return <PrintView />;
	}

	let navbar = (
		<Navbar
			isTocOpen={isTocOpen}
			toggleShare={() => {
				if (!isShareOpen) {
					setIsFontOpen(false);
				}
				setIsShareOpen(!isShareOpen);
			}}
			setIsShareOpen={setIsShareOpen}
			toggleToc={() => {
				setIsTocOpen(!isTocOpen);
			}}
		/>
	);

	let progressBar = <ProgressBar />;
	let fontSlider = <FontSlider />;
	let toc = <TOC />;
	let share = <Share isOpen={isShareOpen} />;

	if (isPrinting) {
		navbar = null;
		progressBar = null;
		fontSlider = null;
		toc = null;
		share = null;
	}

	if (isIframeMode && config.iframe.enabled && !config.iframe.toolbars) {
		className = ['App', styles['issue'], styles['iframe-view']];
	}

	if (fontSizeOption?.enabled === false) {
		fontSlider = null;
	}

	const height = `calc(100% - ${
		theme?.progressBar?.height ? theme?.progressBar?.height : 5
	}px - ${theme?.navbar?.height ? theme?.navbar?.height : 50}px)`;

	return (
		<ResponsiveDesktopContext.Provider
			value={{
				swiper,
				setSwiper,
				isPlayerOpen: !!(isPlayerOpen && player),
				setIsPlayerOpen: setIsPlayerOpen,
				togglePlayer: () => setIsPlayerOpen(!isPlayerOpen),
			}}
		>
			<div className={className.join(' ')}>
				{navbar}
				{progressBar}
				{fontSlider}
				{share}
				<section
					className={styles['container']}
					style={{
						minHeight: height,
						height,
					}}
				>
					{toc}
					<Content isTocOpen={isTocOpen} />
				</section>
			</div>
		</ResponsiveDesktopContext.Provider>
	);
};

const PrintView = () => {
	const { styles } = useContext(AppContext);
	const { article, lang, issue } = useContext(ResponsiveModeContext);
	const { id, style, components, ArticleID } = article;

	return (
		<div className={'print-view'}>
			<div>
				<Article
					id={id}
					index={0}
					style={`${style}`}
					textDirection={issue.textDirection}
					fontSize={45}
					styles={clone(styles)}
					lang={lang}
					ArticleID={ArticleID}
					components={components}
				/>
			</div>
		</div>
	);
};

function clone(obj) {
	if (!obj) return obj;
	if (structuredClone) return structuredClone(obj);
	return JSON.parse(JSON.stringify(obj));
}

const Navbar = ({ isTocOpen, toggleToc, toggleShare, setIsShareOpen }) => {
	const {
		onFontClick,
		onIsFavoriteClick,
		lang,
		isFavorite,
		isOnline,
		isFontOpen,
		article,
		player,
		issue,
		isImageModeCapable,
		onImageModeClick,
	} = useContext(ResponsiveModeContext);

	const { togglePlayer } = useContext(ResponsiveDesktopContext);
	const { config, theme, tts, locale } = useContext(AppContext);

	const { fontSizeOption } = config;

	let bookmarkButton = null;
	let externalButton = null;
	let replicaModeBtn = null;
	const { background, color, height } = theme.navbar;

	const isPlayerEnabled = !!player;

	if (isImageModeCapable && config?.contentType === 'responsive-replica') {
		replicaModeBtn = (
			<button onClick={onImageModeClick}>
				<Icon name="replica-mode" color={color} />
			</button>
		);
	}

	if (navigator.share) {
		toggleShare = () => {
			const share = {
				title: article?.name?.[lang] || '',
				text: article?.description || '',
				url: window.location.href,
			};
			navigator.share(share);
		};
	}

	if (config?.bookmarks?.enabled) {
		const bookmarkLabel = isFavorite
			? 'Unbookmark article'
			: 'Bookmark article';
		bookmarkButton = (
			<button
				onClick={() => {
					onIsFavoriteClick();
					if (!isFavorite) {
						setTimeout(() => {
							alert('Article bookmarked');
						}, 200);
					}
				}}
				aria-label={bookmarkLabel}
				title={bookmarkLabel}
			>
				<Icon
					name={isFavorite ? 'bookmark-selected' : 'bookmark'}
					color={color}
				/>
			</button>
		);
	}

	if (issue?.externalUrl) {
		externalButton = (
			<button
				onClick={() => {
					window.open(issue?.externalUrl, '_blank');
				}}
				aria-label={locale['BUTTON_TITLE_EXTERNAL_URL']}
				title={locale['BUTTON_TITLE_EXTERNAL_URL']}
			>
				<Icon name={'external-link'} color={color} />
			</button>
		);
	}

	return (
		<nav
			className={styles['navbar']}
			style={{ background, height, minHeight: height, maxHeight: height }}
		>
			<div />
			<div style={{ maxHeight: height }} className={styles['logo']}>
				<Link to={`/`}>
					<img
						src={imgLogo}
						style={{ maxHeight: height }}
						alt="logo"
					/>
				</Link>
			</div>
			<div style={{ maxHeight: height }}>
				{!isOnline ? (
					<div className={styles['offline']}>
						<Icon name={'cloud-off'} color={color} />
					</div>
				) : (
					<div className={styles['offline']}>
						<Icon name={'cloud-off'} color={color} opacity={0} />
					</div>
				)}

				<button
					onClick={toggleToc}
					aria-label={'Table of contents'}
					title={'Table of contents'}
				>
					<Icon
						name={isTocOpen ? 'horizontal-split' : 'toc'}
						color={color}
					/>
				</button>
				<LanguageButton article={article} lang={lang} />
				{replicaModeBtn}
				{bookmarkButton}

				{fontSizeOption?.enabled ? (
					<button
						onClick={() => {
							if (!isFontOpen) {
								setIsShareOpen(false);
							}
							onFontClick();
						}}
						title={'Font Size'}
						aria-label={'Font Size'}
					>
						<Icon name={'font'} color={color} />
					</button>
				) : null}

				{tts?.enabled ? (
					<button
						onClick={togglePlayer}
						title={'Player'}
						disabled={!isPlayerEnabled}
						aria-label={'Player'}
					>
						<Icon name={'player'} color={color} />
					</button>
				) : null}
				{externalButton}
				{config?.share?.enabled ? (
					<button
						className={styles['share-button']}
						onClick={toggleShare}
						title={'Share'}
						aria-label={'Share'}
					>
						<Icon name={'share'} color={color} />
					</button>
				) : null}

				<SearchBar />
			</div>
		</nav>
	);
};

Navbar.propTypes = {
	isTocOpen: PropTypes.bool,
	toggleToc: PropTypes.func,
	toggleShare: PropTypes.func,
	setIsShareOpen: PropTypes.func,
};

function LanguageButton({ article, lang }) {
	const { onLanguageSelected } = useContext(ResponsiveModeContext);
	const { onLangChange } = useContext(AppContext);

	const languages = Object.keys(article.name);
	if (languages.length <= 1) {
		return null;
	}

	return (
		<select
			onChange={(e) => {
				console.log(`The selected language is: ${e.target.value}`);
				onLanguageSelected(e.target.value);
				onLangChange(e.target.value);
			}}
			value={lang}
		>
			{languages.map((language) => (
				<option key={language} value={language}>
					{language}
				</option>
			))}
		</select>
	);
}

LanguageButton.propTypes = {
	article: PropTypes.any,
	lang: PropTypes.string,
};

const SearchBar = () => {
	const { onSearch } = useContext(ResponsiveModeContext);
	const { theme } = useContext(AppContext);
	const [isExpanded, setIsExpanded] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 500);
	const { color } = theme.navbar;
	useEffect(() => {
		onSearch(debouncedSearchTerm);
	}, [debouncedSearchTerm]);
	const className = [styles['search-bar']];
	if (isExpanded) {
		className.push(styles['expanded']);
	}
	return (
		<div className={className.join(' ')}>
			{isExpanded ? (
				<span className={styles['icon']}>
					<Icon name={'search-alt'} color={'#c4c4c4'} />
				</span>
			) : (
				<button
					aria-label={'Search'}
					title={'Search'}
					className={styles['icon']}
					onClick={() => setIsExpanded(true)}
				>
					<Icon name={'search-alt'} color={color} />
				</button>
			)}
			{isExpanded ? (
				<input
					type={'text'}
					value={searchTerm}
					onChange={(e) => {
						e.preventDefault();
						setSearchTerm(e.target.value);
					}}
				/>
			) : null}
			{isExpanded ? (
				<button
					title={'Clear'}
					aria-label={'Clear'}
					className={[styles['icon'], styles['close']].join(' ')}
					onClick={() => {
						onSearch('');
						setSearchTerm('');
						setIsExpanded(false);
					}}
				>
					<Icon name={'cancel'} color={'#c4c4c4'} />
				</button>
			) : null}
		</div>
	);
};

const Loading = ({ color }) => {
	return (
		<button className={styles['loading']}>
			<svg x="0px" y="0px" viewBox="0 0 100 100">
				<circle fill={color} stroke="none" cx="25" cy="50" r="6">
					<animate
						attributeName="opacity"
						dur="1s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.1"
					/>
				</circle>
				<circle fill={color} stroke="none" cx="50" cy="50" r="6">
					<animate
						attributeName="opacity"
						dur="1s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.2"
					/>
				</circle>
				<circle fill={color} stroke="none" cx="75" cy="50" r="6">
					<animate
						attributeName="opacity"
						dur="1s"
						values="0;1;0"
						repeatCount="indefinite"
						begin="0.3"
					/>
				</circle>
			</svg>
		</button>
	);
};

Loading.propTypes = {
	color: PropTypes.string,
};

const ProgressBar = () => {
	const { theme } = useContext(AppContext);
	const { index, articles } = useContext(ResponsiveModeContext);
	const percentage = ((index + 1) * 100) / articles.length;
	if (!theme || !theme.progressBar || !theme.progressBar.enabled) {
		return <div className={styles['progress-bar']} />;
	}
	let { height, background, color } = theme.progressBar;
	return (
		<div
			className={styles['progress-bar']}
			style={{
				background: background ? background : '#fff',
				minHeight: height ? `${height}px` : 5,
				height: height ? `${height}px` : 5,
			}}
		>
			<div
				style={{
					width: `${percentage}%`,
					background: color ? color : '#000',
				}}
			/>
		</div>
	);
};

function TOC() {
	const { article, articles } = useContext(ResponsiveModeContext);
	const { theme } = useContext(AppContext);
	const background = theme?.toc?.background || '#fff';
	const listStyleType = theme?.toc?.listStyle || 'none';
	return (
		<aside className={styles['toc']}>
			<ol
				className={styles['toc']}
				style={{
					background,
					listStyleType,
				}}
			>
				{articles
					.map((art, i) => {
						art.index = i;
						return art;
					})
					.filter((article) => article?.channel?.toc !== 'excluded')
					.map((art, i) => (
						<TOCItem
							key={i}
							index={i}
							article={art}
							selected={art?.id === article?.id}
						/>
					))}
			</ol>
		</aside>
	);
}

function TOCItem({ article, selected, index }) {
	const { lang, onTocItemClick } = useContext(ResponsiveModeContext);
	const { theme, config, getReadTime } = useContext(AppContext);

	const { readTime } = config;

	const color = theme?.toc?.color || '#c4c4c4';
	const selectedColor = theme?.toc?.selectedColor || '#000';
	const textTransform = theme?.toc?.textTransform || undefined;
	const fontFamily = theme?.toc?.fontFamily || undefined;
	const selectedFontFamily = theme?.toc?.selectedFontFamily || undefined;
	const style = {
		overflow: 'hidden',
		color: selected ? selectedColor : color,
		fontFamily: selected ? selectedFontFamily : fontFamily,
		textTransform,
		fontWeight: selected ? 800 : undefined,
		borderBottom: `1px groove ${theme?.toc?.background || '#000'}`,
		borderLeft: selected ? `4px solid ${selectedColor}` : undefined,
		borderRight: `1px groove ${theme?.toc?.background || '#000'}`,
	};
	const { name, thumbnail, components, description } = article;

	let componentCSS = `
		#toc-item-${index}:hover {
			color: ${selectedColor} !important;
			border-left: 4px solid ${selectedColor};
		}
	`;

	const isThumbnailsEnabled = !!theme?.toc?.thumbnails?.enabled;
	if (isThumbnailsEnabled) {
		style.display = 'grid';
	} else {
		style.display = 'flex';
		style.padding = 18;
	}

	let readTimeText = '';
	if (readTime?.enabled) {
		let { minutes, seconds } = getReadTime(components, lang);
		if (minutes < 1) {
			readTimeText = ' 1 min';
		} else {
			if (seconds > 30) {
				minutes += 1;
			}

			readTimeText = minutes === 1 ? ` 1 min` : ` ${minutes} min`;
		}
	}

	return (
		<li
			id={`toc-item-${index}`}
			className={styles['item']}
			style={style}
			onClick={() => {
				onTocItemClick(article);
			}}
		>
			{thumbnail && isThumbnailsEnabled ? (
				<div className={styles['image']}>
					<img src={thumbnail} />
				</div>
			) : null}
			<div className={styles['item-content']}>
				<span>{name[lang]}</span>
				{description ? <p>{description}</p> : null}
				{!readTimeText ? null : <small>{readTimeText} read</small>}
			</div>
			<JSXStyle id={`toc-${index}`}>{componentCSS}</JSXStyle>
		</li>
	);
}

TOCItem.propTypes = {
	article: PropTypes.object.isRequired,
	selected: PropTypes.bool.isRequired,
	index: PropTypes.number,
};

const Content = ({ isTocOpen }) => {
	const { isPrinting } = useContext(AppContext);
	const [isLoaded, setIsLoaded] = useState(false);
	const { swiper, setSwiper } = useContext(ResponsiveDesktopContext);
	const { contentRef } = useInternalLink(isLoaded);
	useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true);
		}, 500);
	}, []);
	return (
		<main ref={contentRef} className={styles['content']}>
			{isLoaded ? (
				<Carousel
					id={'carousel-desktop'}
					type="desktop"
					swiper={swiper}
					setSwiper={setSwiper}
				/>
			) : null}

			{isPrinting || isTocOpen ? null : (
				<NavigationArrow direction={'left'} />
			)}
			{isPrinting || isTocOpen ? null : (
				<NavigationArrow direction={'right'} />
			)}
			{isPrinting ? null : <SearchResults />}
			<PlayerToolbar />
		</main>
	);
};

Content.propTypes = {
	isTocOpen: PropTypes.bool.isRequired,
};

const SearchResults = () => {
	const { results } = useContext(ResponsiveModeContext);
	if (!results) {
		return null;
	}
	const className = [styles['search-results']];
	let content = <small>No articles where found</small>;
	if (results.length) {
		content = (
			<ul>
				{results.map((article, i) => (
					<SearchResultItem key={i} article={article} />
				))}
			</ul>
		);
	} else {
		className.push(styles['empty']);
	}
	return <div className={className.join(' ')}>{content}</div>;
};

const SearchResultItem = ({ article }) => {
	const { lang, onTocItemClick } = useContext(ResponsiveModeContext);
	const { getReadTime, config, theme } = useContext(AppContext);
	const { readTime } = config;
	const { name, thumbnail, components } = article;

	let readTimeText = '';
	if (readTime?.enabled) {
		let { minutes, seconds } = getReadTime(components, lang);
		if (minutes < 1) {
			readTimeText = ' 1 min';
		} else {
			if (seconds > 30) {
				minutes += 1;
			}

			readTimeText = minutes === 1 ? ` 1 min` : ` ${minutes} min`;
		}
	}

	const isThumbnailsEnabled = !!theme?.toc?.thumbnails?.enabled;

	return (
		<li
			className={styles['item']}
			style={{
				display: isThumbnailsEnabled ? 'grid' : 'flex',
				padding: isThumbnailsEnabled ? null : 18,
			}}
			onClick={() => {
				onTocItemClick(article);
			}}
		>
			{isThumbnailsEnabled ? (
				<div className={styles['image']}>
					<img src={thumbnail} />
				</div>
			) : null}
			<div className={styles['description']}>
				<h4>{name[lang]}</h4>
				{readTimeText ? <small>{readTimeText} read</small> : null}
			</div>
		</li>
	);
};

SearchResultItem.propTypes = {
	article: PropTypes.object.isRequired,
	index: PropTypes.number,
};

const Share = ({ isOpen }) => {
	const { config } = useContext(AppContext);
	if (!config?.share?.enabled) {
		return null;
	}
	const { article, lang } = useContext(ResponsiveModeContext);
	const title = article.name[lang];
	const className = [styles['share-container']];
	if (!isOpen) {
		className.push(styles['hidden']);
	}

	let onShareClick = () => {
		const el = document.createElement('textarea');
		el.value = window.location.href;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		alert('Copy url to the clipboard');
	};

	const onEmailClick = () => {
		let body = [title, ' ', window.location.href].join(' %0D%0A ');
		const url = `mailto:?to=&body=${body},&subject=${title}`;
		// window.location.href = url;
		window.open(url);
	};

	return (
		<div className={className.join(' ')}>
			<button
				onClick={onShareClick}
				title={'Copy Link'}
				aria-label={'Copy Link'}
			>
				<Icon name={'add-link'} />
				<h4>Copy Link</h4>
			</button>
			<button
				onClick={onEmailClick}
				title={'Attach to email'}
				aria-label={'Attach to email'}
			>
				<Icon name={'attach-email'} />
				<h4>Email</h4>
			</button>
		</div>
	);
};

Share.propTypes = {
	isOpen: PropTypes.bool,
};

const FontSlider = () => {
	let { fontSize, onFontSizeChange, isFontOpen, theme } = useContext(
		ResponsiveModeContext
	);
	const step = 5;
	const lowerLimit = 50;
	const higherLimit = 100;
	const color = `#3d3d3d`;

	const className = [styles['font-slider']];
	if (!isFontOpen) {
		className.push(styles['hidden']);
	}

	useEffect(() => {
		onFontSizeChange(fontSize);
	}, [fontSize]);

	const onChange = (direction) => {
		if (direction === 'increase') {
			fontSize = fontSize + step;
			if (fontSize >= higherLimit) {
				fontSize = higherLimit;
			}
		} else {
			fontSize = fontSize - step;
			if (fontSize <= lowerLimit) {
				fontSize = lowerLimit;
			}
		}
		onFontSizeChange(fontSize);
	};

	const percentage = Math.round(
		((fontSize - lowerLimit) / (higherLimit - lowerLimit)) * 100
	);
	const background = `linear-gradient(to right, ${color} ${percentage}%, ${color} ${percentage}%, rgba(0,0,0,0.3) ${percentage}%, rgba(0,0,0,0.3) 100%)`;
	return (
		<div
			className={className.join(' ')}
			style={{
				height: `${
					theme?.navbar?.height ? theme?.navbar?.height : 50
				}px`,
				marginTop: `calc(${
					theme?.navbar?.height ? theme?.navbar?.height : 50
				}px - ${
					theme?.progressBar?.height ? theme?.progressBar?.height : 5
				}px)`,
			}}
		>
			<div>
				<button
					disabled={fontSize <= lowerLimit}
					title={'Decrease font size'}
					aria-label={'Decrease font size'}
					onClick={() => onChange('decrease')}
				>
					<Icon name={'font-decrease'} />
				</button>
			</div>

			<div>
				<input
					style={{
						border: `0px solid ${color}`,
						background,
					}}
					type="range"
					min={lowerLimit}
					max={higherLimit}
					value={fontSize}
					onChange={(e) => onFontSizeChange(e.target.value)}
					step={1}
				/>
			</div>
			<div>
				<button
					disabled={fontSize >= higherLimit}
					title={'Increase font size'}
					aria-label={'Increase font size'}
					onClick={() => onChange('increase')}
				>
					<Icon name={'font-increase'} />
				</button>
			</div>
		</div>
	);
};

function PlayerToolbar() {
	const { theme } = useContext(AppContext);
	const { article, player } = useContext(ResponsiveModeContext);
	const { isPlayerOpen } = useContext(ResponsiveDesktopContext);
	const [playerState, setPlayerState] = useState(player?.getState());
	const [duration, setDuration] = useState(player?.getState());
	const [position, setPosition] = useState(null);
	const onPlay = (position) => {
		setPosition(Math.floor(position));
	};

	const style = {};

	const isHidden = !isPlayerOpen || !article || !player;
	let { height } = theme.progressBar;
	const progressBarHeight = height ? `${height}px` : '5px';
	useEffect(() => {
		setPlayerState(player?.getState());
		setDuration(Math.floor(player?.getDuration()));
		if (!isPlayerOpen && playerState === 'playing' && player) {
			setPlayerState('stop');
			player.stop();
		}
		if (player) {
			player.onChange('position', onPlay);
		}
	}, [player, article, isPlayerOpen]);
	let onPlayPauseClick = () => {
		setPlayerState('playing');
		player.play();
	};
	if (playerState === 'playing') {
		onPlayPauseClick = () => {
			setPlayerState('pause');
			player.pause();
		};
	}
	const onReplayClick = () => {
		player.rewind(5);
	};
	const onForwardClick = () => {
		player.forward(5);
	};
	const className = [styles['player-toolbar']];
	if (isHidden) {
		className.push(styles['hidden']);
		style.marginTop = `calc(-1 * var(--bottom-bar-height) - ${progressBarHeight} - 2px)`;
	} else {
		className.push(styles['show']);
	}
	return (
		<div className={className.join(' ')} style={style}>
			<div className={styles['controls']}>
				<button
					title={'Backwards 5 seconds'}
					aria-label={'Backwards 5 seconds'}
					onClick={onReplayClick}
					disabled={playerState !== 'playing'}
				>
					<Icon name={'replay-5'} />
				</button>
				<button
					title={'Play / Pause'}
					aria-label={'Play / Pause'}
					onClick={onPlayPauseClick}
				>
					<Icon
						name={
							playerState === 'playing' ? 'pause' : 'play-arrow'
						}
					/>
				</button>
				<button
					title={'Forward 5 seconds'}
					aria-label={'Forward 5 seconds'}
					onClick={onForwardClick}
					disabled={playerState !== 'playing'}
				>
					<Icon name={'forward-5'} />
				</button>
			</div>
			<div className={styles['progress']}>
				{duration
					? `${formatTime(position)} / ${formatTime(duration)}`
					: null}
			</div>
		</div>
	);
}

const NavigationArrow = ({ direction }) => {
	let {
		device: { isTouchable },
		navArrows,
	} = useContext(AppContext);
	const {
		index,
		articles,
		onArticleNextClick,
		onArticlePreviousClick,
		isLightboxMode,
	} = useContext(ResponsiveModeContext);

	if (!navArrows?.enabled) {
		return null;
	}
	if (navArrows?.mode === 'always') {
		isTouchable = false;
	}
	const className = [styles['navigation-arrow'], styles[direction]];
	if (isTouchable) {
		return null;
	}
	if (isLightboxMode) {
		return null;
	}
	if (index === 0 && direction === 'left') {
		return null;
	}
	if (index === articles.length - 1 && direction === 'right') {
		return null;
	}
	let onClick =
		direction === 'right' ? onArticleNextClick : onArticlePreviousClick;
	const arrowLabel =
		direction === 'left' ? 'Previous Article' : 'Next Article';
	return (
		<div className={className.join(' ')}>
			<button
				onClick={onClick}
				title={arrowLabel}
				aria-label={arrowLabel}
			>
				<Icon name={`chevron-${direction}`} color={'#000'} />
			</button>
		</div>
	);
};

NavigationArrow.propTypes = {
	direction: PropTypes.oneOf(['left', 'right']),
};
