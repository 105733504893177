import React, { useContext, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ArticleContext } from '../Context';
import { Caption } from './Caption';
import imageStyles from '../article.module.css';
import { AppContext } from '../../../../services/AppContext';

import { useIsInViewport } from '../hooks/use-viewport';
import { useComponentAnimation } from '../hooks/use-animation';

const DEFAULT_HEIGHT = 500;

export const StyledImage = ({
	id,
	fullwidth,
	align,
	style,
	expandfullwidth,
	bleed,
	caption,
	animation,
	width,
	credit,
	imagelink,
	captionenabled,
	fixedwidth,
	creditenabled,
	imageurl,
	htmlclass,
	imageclip,
	lightbox,
	externallinktarget,
	linktype,
}) => {
	const articleContext = useContext(ArticleContext);
	const { lang } = useContext(ArticleContext);
	if (typeof caption === 'object') {
		caption = caption[lang] ? caption[lang] : '';
	}
	if (typeof credit === 'object') {
		credit = credit[lang] ? credit[lang] : '';
	}

	if (typeof width === 'string') {
		width = parseInt(width);
	}

	let marginBottom = 0;
	if (articleContext?.style?.canvas?.componentBottomMargin) {
		marginBottom = parseInt(
			`${articleContext.style['canvas']?.componentBottomMargin}`
		);
	}

	return (
		<Image
			id={id}
			align={align}
			bleed={bleed}
			fullWidth={fullwidth === 'on'}
			expandFullWidth={expandfullwidth === 'on'}
			caption={caption}
			imageClip={imageclip}
			captionEnabled={captionenabled === 'on'}
			externalLinkTarget={externallinktarget}
			animation={animation}
			linkType={linktype}
			style={style}
			width={width || null}
			htmlClass={htmlclass?.length ? htmlclass.split(' ') : []}
			credit={credit}
			creditEnabled={creditenabled === 'on'}
			link={imagelink}
			url={imageurl}
			marginBottom={marginBottom}
			fixedWidth={fixedwidth === 'on'}
			lightbox={lightbox === 'on'}
		/>
	);
};

StyledImage.propTypes = {
	id: PropTypes.string,
	align: PropTypes.string,
	bleed: PropTypes.oneOf(['on', 'off', 'left', 'right']),
	style: PropTypes.any,
	fullwidth: PropTypes.string,
	animation: PropTypes.object,
	expandfullwidth: PropTypes.string,
	externallinktarget: PropTypes.string,
	linktype: PropTypes.string,
	caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	captionenabled: PropTypes.string,
	captionposition: PropTypes.string,
	credit: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	creditenabled: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fixedwidth: PropTypes.string,
	imagelink: PropTypes.string,
	imageurl: PropTypes.string,
	imageClip: PropTypes.string,
	onClick: PropTypes.func,
	imageclip: PropTypes.string,
	lightbox: PropTypes.string,
	htmlclass: PropTypes.string,
};

export const Image = ({
	id,
	align,
	bleed,
	fullWidth,
	animation,
	externalLinkTarget,
	// linkType,
	// expandFullWidth,
	caption,
	captionEnabled,
	credit,
	creditEnabled,
	link,
	url,
	width,
	fixedWidth,
	imageClip,
	onClick,
	lightbox,
	htmlClass,
	style,
}) => {
	const ref = useRef();

	const [animationClasses, setAnimationClasses] = useState([]);
	let css = [];
	let classNames = ['media', 'image', imageStyles['image']];

	const hasAnimation = animation && animation.type !== 'none';
	const isInViewport = useIsInViewport(ref, hasAnimation);
	useComponentAnimation(ref, isInViewport, animation, setAnimationClasses);

	let { isPrinting } = useContext(AppContext);
	const articleContext = useContext(ArticleContext);
	const { onLightboxClick } = articleContext;
	let containerStyle = {};
	let imageStyle = useMemo(() => {
		const clipPath = getClipPath(imageClip);
		if (fixedWidth && width && !fullWidth) {
			return {
				clipPath,
				maxWidth: `min(${width}px, 100%)`,
			};
		}

		if (fullWidth) {
			return {
				clipPath,
				maxWidth: '100%',
			};
		}

		return {};
	}, []);
	const captionStyle = useMemo(() => {
		if (fixedWidth && width && !fullWidth) {
			return {
				maxWidth: `min(${width}px, 100%)`,
			};
		}

		if (fullWidth) {
			return {
				maxWidth: '100%',
			};
		}

		return {};
	}, []);

	if (htmlClass?.length) {
		classNames = [...classNames, ...htmlClass];
	}

	switch (align) {
		case 'left':
			containerStyle.alignItems = 'flex-start';
			break;
		case 'right':
			containerStyle.alignItems = 'flex-end';
			break;
		case 'float-left':
			classNames.push(imageStyles['float-left'], 'float');
			break;
		case 'float-right':
			classNames.push(imageStyles['float-right'], 'float');
			break;
		default:
			containerStyle.alignItems = 'center';
			break;
	}

	let click = () => {};
	if (link) {
		containerStyle.cursor = 'pointer';
		click = () => {
			if (externalLinkTarget === 'off') {
				window.location = link;
			} else {
				window.open(link);
			}
		};
	} else {
		if (onClick) {
			containerStyle.cursor = 'pointer';
			click = onClick;
		}
	}

	switch (bleed) {
		case 'on':
			classNames.push('bleed');
			break;
		case 'left':
		case 'right':
			classNames.push(`bleed-${bleed}`);
			break;
		default:
			break;
	}

	if (lightbox) {
		containerStyle.cursor = 'pointer';
		click = () => {
			onLightboxClick({ id });
		};
	}

	if (style) {
		setImageInlineStyles(containerStyle, style);
	}

	return (
		<figure
			id={id}
			ref={ref}
			className={[...classNames, ...animationClasses].join(' ')}
			style={containerStyle}
			onClick={click}
		>
			{isPrinting || window.location.hash ? (
				<img
					src={`${url.replace(/\\/g, '/')}`}
					width={articleContext.width}
					alt={caption || ''}
					height={DEFAULT_HEIGHT}
					style={imageStyle}
				/>
			) : (
				<LazyLoadImage
					src={`${url.replace(/\\/g, '/')}`}
					alt={caption || ''}
					height={DEFAULT_HEIGHT}
					style={imageStyle}
				/>
			)}

			{captionEnabled && caption ? (
				<Caption style={captionStyle} content={caption} />
			) : null}
			{creditEnabled && credit ? (
				<Credit style={captionStyle} content={credit} />
			) : null}
			<style>{css.join('\n')}</style>
		</figure>
	);
};

function setImageInlineStyles(containerStyle, style) {
	if (!style) {
		return;
	}

	if (!style.unit) {
		return;
	}

	const { unit } = style;

	const attributes = {
		margin_top: 'marginTop',
		margin_right: 'marginRight',
		margin_bottom: 'marginBottom',
		margin_left: 'marginLeft',
		padding_top: 'paddingTop',
		padding_right: 'paddingRight',
		padding_bottom: 'paddingBottom',
		padding_left: 'paddingLeft',
	};

	for (const attr in attributes) {
		if (style[attr] !== undefined && unit[attr]) {
			containerStyle[attributes[attr]] = `${style[attr]}${unit[attr]}`;
		}
	}
}

Image.propTypes = {
	id: PropTypes.string,
	align: PropTypes.string,
	bleed: PropTypes.oneOf(['on', 'off', 'left', 'right']),
	style: PropTypes.any,
	fullWidth: PropTypes.bool,
	expandFullWidth: PropTypes.bool,
	externalLinkTarget: PropTypes.string,
	caption: PropTypes.string,
	captionEnabled: PropTypes.bool,
	captionPosition: PropTypes.bool,
	credit: PropTypes.string,
	animation: PropTypes.object,
	creditEnabled: PropTypes.bool,
	width: PropTypes.number,
	fixedWidth: PropTypes.bool,
	link: PropTypes.string,
	url: PropTypes.string,
	imageClip: PropTypes.string,
	onClick: PropTypes.func,
	linkType: PropTypes.string,
	marginBottom: PropTypes.number,
	lightbox: PropTypes.bool,
	htmlClass: PropTypes.array,
};

Image.defaultProps = {
	captionEnabled: true,
	creditEnabled: true,
	marginBottom: 0,
};

function getClipPath(imageClip) {
	switch (imageClip) {
		case 'circle':
			return 'circle(50% at 50% 50%)';
		case 'ellipse':
			return 'ellipse(25% 40% at 50% 50%)';
		case 'triangle':
			return 'polygon(50% 0%, 0% 100%, 100% 100%)';
		case 'trapezoid':
			return 'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)';
		case 'parallelogram':
			return 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)';
		case 'rhombus':
			return 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)';
		case 'pentagon':
			return 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)';
		case 'hexagon':
			return 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)';
		case 'heptagon':
			return 'polygon(50% 0%,90% 20%, 100% 60%,75% 100%, 25% 100%,0% 60%,10% 20%)';
		case 'octagon':
			return 'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)';
		case 'nonagon':
			return 'polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)';
		case 'decogon':
			return 'polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)';
		case 'bevel':
			return 'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)';
		case 'rabbet':
			return 'polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%)';
		case 'leftarrow':
			return 'polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)';
		case 'rightarrow':
			return 'polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)';
		case 'leftpoint':
			return 'polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)';
		case 'rightpoint':
			return 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)';
		case 'rightchevron':
			return 'polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)';
		case 'leftchevron':
			return 'polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)';
		case 'star':
			return 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)';
		case 'close':
			return 'polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%)';
		default:
			return undefined;
	}
}

const Credit = ({ content, style }) => {
	if (!style) {
		style = {};
	}
	return (
		<cite
			className={imageStyles['credit']}
			style={style}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
};

Credit.propTypes = {
	content: PropTypes.string,
	style: PropTypes.any,
};

Credit.defaultProps = {
	content: '',
	style: {},
};
