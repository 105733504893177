import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Article } from '../../../components/canvasflow/article/Article';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ResponsiveModeContext } from './ResponsiveModeContext';
import SwiperCore, { EffectFade } from 'swiper/core';

import cssStyles from './ResponsiveMode.module.css';

SwiperCore.use([EffectFade]);

// Import Swiper styles
import 'swiper/swiper.scss';
import { AppContext } from '../../../services/AppContext';
import { useEffect } from 'react';
export const Carousel = (props) => {
	const { id, type, swiper, setSwiper } = props;
	const { styles } = useContext(AppContext);
	let {
		articles,
		fontSize,
		article,
		onTransitionEnd,
		onLightboxMode,
		isLightboxMode,
		isTouchDevice,
		lang,
		onLightboxTargetClick,
		issue,
	} = useContext(ResponsiveModeContext);

	const { index } = article;

	if (props.onTransitionEnd) {
		onTransitionEnd = props.onTransitionEnd;
	}

	useEffect(() => {
		if (!swiper) {
			return null;
		}
		console.log(`GO TO THIS INDEX: `, index);
		console.log(swiper);
		swiper.slideTo(index, 750, false);
	}, [index]);

	const allowToMove = isTouchDevice && !isLightboxMode;

	useEffect(() => {
		if (!swiper) {
			return null;
		}
		const toggle = () => {
			try {
				if (allowToMove) {
					swiper.enable();
				} else {
					swiper.disable();
				}
			} catch (e) {
				console.error(e);
				toggle();
			}
		};
		toggle();
	}, [isLightboxMode]);

	return (
		<div id={id} className={cssStyles['swiper-container']}>
			<Swiper
				updateOnWindowResize={true}
				initialSlide={index}
				scrollbar={{
					draggable: false,
				}}
				onBeforeDestroy={() => {
					setSwiper(null);
				}}
				onSwiper={(s) => {
					setSwiper(s);
				}}
				onSlideChange={(s) => {
					const { activeIndex } = s;
					onTransitionEnd({
						index: activeIndex,
					});
				}}
			>
				{articles.map((article, i) => {
					let { id, style, components, ArticleID } = article;
					let isVoid = isArticleVoid({
						total: articles.length,
						currentIndex: index,
						index: i,
					});

					const key = `${type}-${i}`;

					// The article is not currently selected
					if (i !== index) {
						components = clone(
							components.filter(
								(component) => component.component !== 'video'
							)
						);
					}
					return (
						<SwiperSlide
							key={key}
							className={cssStyles['article-swiper']}
						>
							<Article
								id={id}
								ArticleID={ArticleID}
								index={i}
								style={`${style}`}
								lang={lang}
								isSelected={i === index}
								textDirection={issue.textDirection}
								isVoid={isVoid}
								fontSize={fontSize}
								styles={styles}
								isTouchDevice={isTouchDevice}
								components={components}
								onLightboxMode={onLightboxMode}
								onLightboxTargetClick={onLightboxTargetClick}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

Carousel.propTypes = {
	id: PropTypes.string,
	type: PropTypes.string,
	swiper: PropTypes.any,
	setSwiper: PropTypes.func,
	onTransitionEnd: PropTypes.func,
};

/*function isArticleVoid({ currentIndex, index }) {
	return currentIndex !== index
}*/

function isArticleVoid({ total, currentIndex, index }) {
	if (total > 5) {
		const lastArticleIndex = total - 1;
		if (currentIndex === 0) {
			// First and second page
			if (index !== 0 && index !== 1 && index !== 2) {
				return true;
			}
		} else if (currentIndex === lastArticleIndex) {
			// Last 2 articles
			if (
				index !== lastArticleIndex &&
				index !== lastArticleIndex - 1 &&
				index !== lastArticleIndex - 2
			) {
				return true;
			}
		} else {
			if (
				index !== currentIndex &&
				index !== currentIndex - 1 &&
				index !== currentIndex - 2 &&
				index !== currentIndex + 1 &&
				index !== currentIndex + 2
			) {
				return true;
			}
		}
	}
	return false;
}

function clone(obj) {
	if (structuredClone) {
		return obj;
	}

	return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}
