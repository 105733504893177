// eslint no-unused-vars
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import imgLogo from '../../../components/navbar/logo.png';
import { Icon } from '../../../components/Icon';
import styles from './ResponsiveMode.mobile.module.css';
import {
	ResponsiveModeContext,
	formatTime,
	useInternalLink,
} from './ResponsiveModeContext';
import { Carousel } from './Carousel';
import { AppContext } from '../../../services/AppContext';
import useDebounce from '../../../hooks/use-debounce';
import { useBalanceText } from './ResponsiveMode.hooks';

export const ResponsiveMobileContext = React.createContext({
	isTocOpen: false,
	setMode: null,
	mode: 'issue',
	searchTerm: '',
	onSearchChange: null,
	isPlayerOpen: false,
	setIsPlayerOpen: null,
	togglePlayer: null,
	toggleToc: null,
});

export const ResponsiveModeMobile = () => {
	const [swiper, setSwiper] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const { player } = useContext(ResponsiveModeContext);
	const [mode, setMode] = useState('issue');
	const [isTocOpen, setIsTocOpen] = useState(false);
	const [isPlayerOpen, setIsPlayerOpen] = useState(false);

	useBalanceText();

	useEffect(() => {
		if (swiper) {
			console.log(`SWIPER IN Mobile`, swiper);
			try {
				swiper.update();
				swiper.init();
			} catch (e) {
				console.error(e);
			}
		}
	}, [swiper]);

	return (
		<div className={['App', styles['issue']].join(' ')}>
			<ResponsiveMobileContext.Provider
				value={{
					setMode,
					swiper,
					setSwiper,
					mode,
					isTocOpen,
					searchTerm,
					onSearchChange: setSearchTerm,
					isPlayerOpen: !!(isPlayerOpen && player),
					setIsPlayerOpen: setIsPlayerOpen,
					togglePlayer: () => setIsPlayerOpen(!isPlayerOpen),
					toggleToc: () => setIsTocOpen(!isTocOpen),
				}}
			>
				{mode === 'search' ? <SearchMode /> : <IssueMode />}
			</ResponsiveMobileContext.Provider>
		</div>
	);
};

const IssueMode = () => {
	const { config, isIframeMode, theme, locale } = useContext(AppContext);
	const {
		article,
		articles,
		lang,
		issue,
		isImageModeCapable,
		onImageModeClick,
	} = useContext(ResponsiveModeContext);
	const { isTocOpen } = useContext(ResponsiveMobileContext);
	const asideClassName = [styles['toc']];
	let shareButton = null;
	let externalButton = null;
	let replicaModeBtn = null;

	let { color, background, height } = theme.navbar;

	if (isImageModeCapable) {
		replicaModeBtn = (
			<button onClick={onImageModeClick}>
				<Icon name="replica-mode" color={color} />
			</button>
		);
	}

	if (issue?.externalUrl) {
		externalButton = (
			<button
				onClick={() => {
					window.open(issue?.externalUrl, '_blank', 'download');
				}}
				aria-label={locale['BUTTON_TITLE_EXTERNAL_URL']}
				title={locale['BUTTON_TITLE_EXTERNAL_URL']}
			>
				<Icon name={'external-link'} color={color} />
			</button>
		);
	}

	if (navigator) {
		let onShareClick = () => {
			const el = document.createElement('textarea');
			el.value = window.location.href;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			alert('Copy url to the clipboard');
		};
		if (navigator.share) {
			onShareClick = () => {
				const share = {
					title: article?.name?.[lang] || '',
					text: article?.description || '',
					url: window.location.href,
				};
				navigator.share(share);
			};
		}
		shareButton = config?.share?.enabled ? (
			<button onClick={onShareClick} title={'Share'} aria-label={'Share'}>
				<Icon name={'share'} color={color} />
			</button>
		) : null;
	}

	() => {
		navigator.clipboard.writeText(window.location.href);
	};

	let className = styles['issue-view'];
	if (isIframeMode && config.iframe.enabled && !config.iframe.toolbars) {
		className = styles['iframe-view'];
	}

	height = height ? height : 50;

	return (
		<div className={className}>
			<nav
				className={styles['navbar']}
				style={{
					background,
					height,
					minHeight: height,
					maxHeight: height,
				}}
			>
				<div />
				<div className={styles['logo']} style={{ maxHeight: height }}>
					<Link to={`/`}>
						<img
							src={imgLogo}
							style={{ maxHeight: height }}
							alt="logo"
						/>
					</Link>
				</div>
				<div
					className={styles['actions']}
					style={{ maxHeight: height }}
				>
					{replicaModeBtn}
					{externalButton}
					{shareButton}
				</div>
			</nav>
			<main
				style={{
					minHeight: `calc(100% - ${height}px - var(--bottom-bar-height) -
					env(safe-area-inset-bottom, var(--safe-area-inset)))`,
					height: `calc(100% - ${height}px - var(--bottom-bar-height) -
					env(safe-area-inset-bottom, var(--safe-area-inset)))`,
				}}
				className={
					isTocOpen ? styles['toc-open'] : styles['toc-closed']
				}
			>
				<aside className={asideClassName.join(' ')}>
					<ul>
						{articles
							.filter((art) => art?.channel?.toc !== 'excluded')
							.map((art) => (
								<TocItem
									key={art.id}
									article={art}
									selected={art.id === article.id}
								/>
							))}
					</ul>
				</aside>
				<section className={styles['content']}>
					<ProgressBar />
					<Content />
				</section>
			</main>
			<Toolbar />
			<PlayerToolbar />
		</div>
	);
};

function TocItem({ article, selected }) {
	const { getReadTime, theme, config } = useContext(AppContext);
	const { lang, onTocItemClick } = useContext(ResponsiveModeContext);
	const { toggleToc } = useContext(ResponsiveMobileContext);

	const isThumbnailsEnabled = !!theme?.toc?.thumbnails?.enabled;
	const { readTime } = config;

	const { id, name, description, thumbnail, components } = article;
	const title = name?.[lang];
	let readTimeText = '';
	if (readTime?.enabled) {
		let { minutes, seconds } = getReadTime(components, lang);
		if (minutes < 1) {
			readTimeText = ' 1 min';
		} else {
			if (seconds > 30) {
				minutes += 1;
			}

			readTimeText = minutes === 1 ? ` 1 min` : ` ${minutes} min`;
		}
	}

	const fontFamily = theme?.toc?.fontFamily || undefined;
	const selectedFontFamily = theme?.toc?.selectedFontFamily || undefined;

	const style = {
		cursor: 'pointer',
		display: isThumbnailsEnabled ? 'grid' : 'flex',
		padding: isThumbnailsEnabled ? null : 12,
		fontFamily: selected ? selectedFontFamily : fontFamily,
	};

	if (title) {
		return (
			<li
				id={`toc-article-${id}`}
				key={id}
				onClick={() => {
					onTocItemClick(article);
					toggleToc();
				}}
				style={style}
				className={selected ? styles['selected'] : null}
			>
				{isThumbnailsEnabled ? (
					<div>
						<img src={thumbnail} />
					</div>
				) : null}
				<div className={styles['item-content']}>
					<h4>{title}</h4>
					{description ? <p>{description}</p> : null}
					{!readTimeText ? null : <small>{readTimeText} read</small>}
				</div>
			</li>
		);
	}
	return null;
}

TocItem.propTypes = {
	article: PropTypes.object,
	selected: PropTypes.bool,
};

const SearchMode = () => {
	const { theme } = useContext(AppContext);
	const { searchTerm } = useContext(ResponsiveMobileContext);
	const { results } = useContext(ResponsiveModeContext);
	const articles = results?.length ? results : [];
	let content = (
		<div className={styles['empty']}>
			<div>
				<Icon name={'search'} color={'#c4c4c4'} />
				<p>Please enter a search term</p>
			</div>
		</div>
	);
	if (articles.length) {
		content = (
			<ul>
				{articles.map((article, i) => (
					<SearchItem key={i} article={article} />
				))}
			</ul>
		);
	} else if (searchTerm) {
		content = (
			<div className={styles['not-found']}>
				<div>
					<Icon name={'search'} color={'#000'} />
					<p>No results, please try again</p>
				</div>
			</div>
		);
	}
	let { height } = theme.navbar;
	height = height ? height : 50;

	return (
		<div className={styles['search-view']}>
			<SearchBar />
			<main
				style={{
					minHeight: `calc(100% - ${height}px - var(--bottom-bar-height) -
					env(safe-area-inset-bottom, var(--safe-area-inset)))`,
					height: `calc(100% - ${height}px - var(--bottom-bar-height) -
					env(safe-area-inset-bottom, var(--safe-area-inset)))`,
				}}
				className={styles['content']}
			>
				{content}
			</main>
			<Toolbar />
		</div>
	);
};

const SearchBar = () => {
	const { theme } = useContext(AppContext);
	const { searchTerm, onSearchChange } = useContext(ResponsiveMobileContext);
	const { onSearch } = useContext(ResponsiveModeContext);
	const debouncedSearchTerm = useDebounce(searchTerm, 250);
	const { background, height, color } = theme.navbar;
	useEffect(() => {
		onSearch(debouncedSearchTerm);
	}, [debouncedSearchTerm]);
	return (
		<nav
			className={styles['navbar']}
			style={{ background, height, minHeight: height, maxHeight: height }}
		>
			<div className={styles['search-bar']} style={{ maxHeight: height }}>
				<span className={styles['icon']}>
					<Icon name={'search-alt'} color={color} />
				</span>
				<input
					type={'text'}
					placeholder={'Search'}
					value={searchTerm}
					style={{ background, color, border: `1px solid ${color}` }}
					onChange={(e) => {
						e.preventDefault();
						onSearchChange(e.target.value);
					}}
				/>
			</div>
		</nav>
	);
};

const SearchItem = ({ article }) => {
	const { id, name, thumbnail, components } = article;
	const { setMode } = useContext(ResponsiveMobileContext);
	const { onTocItemClick, lang } = useContext(ResponsiveModeContext);
	const { getReadTime, config, theme } = useContext(AppContext);
	const { readTime } = config;
	let readTimeText = '';
	if (readTime?.enabled) {
		let { minutes, seconds } = getReadTime(components, lang);
		if (minutes < 1) {
			readTimeText = ' 1 min';
		} else {
			if (seconds > 30) {
				minutes += 1;
			}

			readTimeText = minutes === 1 ? ` 1 min` : ` ${minutes} min`;
		}
	}
	const isThumbnailsEnabled = !!theme?.toc?.thumbnails?.enabled;

	return (
		<li
			id={`item-article-${id}`}
			style={{
				display: isThumbnailsEnabled ? 'grid' : 'flex',
				padding: isThumbnailsEnabled ? null : 12,
			}}
			onClick={() => {
				onTocItemClick(article);
				setMode('issue');
			}}
		>
			{isThumbnailsEnabled ? (
				<div>
					<img src={thumbnail} />
				</div>
			) : null}
			<div>
				<h4>{name[lang]}</h4>
				{!readTimeText ? null : <small>{readTimeText} read</small>}
			</div>
		</li>
	);
};

SearchItem.propTypes = {
	article: PropTypes.object,
};

function TOC() {
	const { article, articles } = useContext(ResponsiveModeContext);
	const asideClassName = [styles['toc']];

	return (
		<aside className={asideClassName.join(' ')}>
			<ul>
				{articles
					.filter((article) => article?.channel?.toc !== 'excluded')
					.map((art) => (
						<TOCItem
							key={art.id}
							article={art}
							selected={article.id === art.id}
						/>
					))}
			</ul>
		</aside>
	);
}

TOC.propTypes = {
	articles: PropTypes.array.isRequired,
	article: PropTypes.object,
	lang: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

function TOCItem({ article, selected }) {
	const { getReadTime, config } = useContext(AppContext);
	const { lang, onTocItemClick } = useContext(ResponsiveModeContext);
	const { toggleToc } = useContext(ResponsiveMobileContext);
	const { readTime } = config;
	const { id, name, thumbnail, components } = article;
	const title = name?.[lang];
	let readTimeText = '';
	if (readTime?.enabled) {
		let { minutes, seconds } = getReadTime(components, lang);
		if (minutes < 1) {
			readTimeText = ' 1 min';
		} else {
			if (seconds > 30) {
				minutes += 1;
			}

			readTimeText = minutes === 1 ? ` 1 min` : ` ${minutes} min`;
		}
	}

	if (title) {
		return (
			<li
				id={`toc-article-${id}`}
				key={id}
				onClick={() => {
					onTocItemClick(article);
					toggleToc();
				}}
				className={selected ? styles['selected'] : null}
			>
				<div>
					<img src={thumbnail} />
				</div>
				<div>
					<h4>{title}</h4>
					{!readTimeText ? null : <small>{readTimeText} read</small>}
				</div>
			</li>
		);
	}
	return null;
}

TOCItem.propTypes = {
	article: PropTypes.object.isRequired,
	selected: PropTypes.bool.isRequired,
};

export function ProgressBar() {
	const { theme } = useContext(AppContext);
	const { index, articles } = useContext(ResponsiveModeContext);

	const percentage = ((index + 1) * 100) / articles.length;
	if (!theme || !theme.progressBar || !theme.progressBar.enabled) {
		return <div className={styles['progress-bar']} />;
	}
	let { height, background, color } = theme.progressBar;
	return (
		<div
			className={styles['progress-bar']}
			style={{
				background: background ? background : '#fff',
				height: height ? `${height}px` : 5,
			}}
		>
			<div
				style={{
					width: `${percentage}%`,
					background: color ? color : '#000',
				}}
			/>
		</div>
	);
}

export function Content() {
	const [isLoaded, setIsLoaded] = useState(false);
	const { isPrinting } = useContext(AppContext);
	const { swiper, setSwiper, setIsPlayerOpen } = useContext(
		ResponsiveMobileContext
	);
	const { onTransitionEnd } = useContext(ResponsiveModeContext);
	const { contentRef } = useInternalLink(isLoaded);
	useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true);
		}, 500);
	}, []);
	return (
		<div ref={contentRef} style={{ height: 'calc(100% - 5px)' }}>
			{isLoaded ? (
				<Carousel
					id={'carousel-mobile'}
					type="mobile"
					swiper={swiper}
					setSwiper={setSwiper}
					onTransitionEnd={(data) => {
						setIsPlayerOpen(false);
						onTransitionEnd(data);
					}}
				/>
			) : null}
			{isPrinting ? null : <NavigationArrow direction={'left'} />}
			{isPrinting ? null : <NavigationArrow direction={'right'} />}
		</div>
	);
}

function Toolbar() {
	const { tts, config, theme } = useContext(AppContext);
	const { isFavorite, onIsFavoriteClick, player, lang, article } = useContext(
		ResponsiveModeContext
	);
	const { mode, toggleToc, setMode, togglePlayer } = useContext(
		ResponsiveMobileContext
	);
	const background = theme?.bottomBar?.background || '#3d3d3d';
	const color = theme?.bottomBar?.color || '#fff';

	const isPlayerEnabled = mode === 'issue' && player;
	let bookmarkButton = null;
	if (config?.bookmarks?.enabled) {
		const bookmarkLabel = isFavorite
			? 'Unbookmark article'
			: 'Bookmark article';
		bookmarkButton = (
			<button
				disabled={mode === 'search'}
				onClick={() => {
					onIsFavoriteClick();
					if (!isFavorite) {
						setTimeout(() => {
							alert('Article bookmarked');
						}, 200);
					}
				}}
				title={bookmarkLabel}
				aria-label={bookmarkLabel}
			>
				<Icon
					name={isFavorite ? 'bookmark-selected' : 'bookmark'}
					color={color}
				/>
			</button>
		);
	}
	return (
		<footer className={styles['toolbar']} style={{ background }}>
			{mode === 'search' ? (
				<button
					onClick={() => {
						setMode('issue');
					}}
					title={'Issue'}
					aria-label={'Issue'}
				>
					<Icon name={'article'} color={color} />
				</button>
			) : (
				<button
					onClick={toggleToc}
					title={'Table of content'}
					aria-label={'Table of content'}
				>
					<Icon name={'toc'} color={color} />
				</button>
			)}
			<button
				title={'search'}
				aria-label={'search'}
				onClick={() => {
					setMode('search');
				}}
			>
				<Icon name={'search'} color={color} />
			</button>
			<LanguageButton article={article} lang={lang} />
			{bookmarkButton}
			{tts?.enabled ? (
				<button
					title={'Player'}
					aria-label={'Player'}
					disabled={!isPlayerEnabled}
					onClick={togglePlayer}
				>
					<Icon name={'player'} color={color} />
				</button>
			) : null}
		</footer>
	);
}

function PlayerToolbar() {
	const { article, player } = useContext(ResponsiveModeContext);
	const { isPlayerOpen } = useContext(ResponsiveMobileContext);
	const [playerState, setPlayerState] = useState(player?.getState());
	const [duration, setDuration] = useState(player?.getState());
	const [position, setPosition] = useState(null);
	const onPlay = (position) => {
		setPosition(Math.floor(position));
	};
	const isHidden = !isPlayerOpen || !article || !player;
	useEffect(() => {
		setPlayerState(player?.getState());
		setDuration(Math.floor(player?.getDuration()));
		if (!isPlayerOpen && playerState === 'playing' && player) {
			setPlayerState('stop');
			player.stop();
		}
		if (player) {
			player.onChange('position', onPlay);
		}
	}, [player, article, isPlayerOpen]);
	const { thumbnail } = article;
	let onPlayPauseClick = () => {
		setPlayerState('playing');
		player.play();
	};
	if (playerState === 'playing') {
		onPlayPauseClick = () => {
			setPlayerState('pause');
			player.pause();
		};
	}
	const onReplayClick = () => {
		player.rewind(5);
	};
	const onForwardClick = () => {
		player.forward(5);
	};
	const className = [styles['player-toolbar']];
	if (isHidden) {
		className.push(styles['hidden']);
	} else {
		className.push(styles['show']);
	}
	return (
		<div className={className.join(' ')}>
			<div className={styles['thumbnail']}>
				<img src={thumbnail} />
			</div>
			<div className={styles['controls']}>
				<button
					title={'Backwards 5 seconds'}
					aria-label={'Backwards 5 seconds'}
					onClick={onReplayClick}
					disabled={playerState !== 'playing'}
				>
					<Icon name={'replay-5'} />
				</button>
				<button
					title={'Play / Pause'}
					aria-label={'Play / Pause'}
					onClick={onPlayPauseClick}
				>
					<Icon
						name={
							playerState === 'playing' ? 'pause' : 'play-arrow'
						}
					/>
				</button>
				<button
					title={'Forward 5 seconds'}
					aria-label={'Forward 5 seconds'}
					onClick={onForwardClick}
					disabled={playerState !== 'playing'}
				>
					<Icon name={'forward-5'} />
				</button>
			</div>
			<div className={styles['progress']}>
				{duration
					? `${formatTime(position)} / ${formatTime(duration)}`
					: null}
			</div>
		</div>
	);
}

const NavigationArrow = ({ direction }) => {
	let {
		device: { isTouchable },
		navArrows,
	} = useContext(AppContext);
	const {
		index,
		articles,
		onArticleNextClick,
		onArticlePreviousClick,
		isLightboxMode,
	} = useContext(ResponsiveModeContext);
	if (!navArrows?.enabled) {
		return null;
	}
	if (navArrows?.mode === 'always') {
		isTouchable = false;
	}
	const className = [styles['navigation-arrow'], styles[direction]];
	if (isTouchable) {
		return null;
	}
	if (isLightboxMode) {
		return null;
	}
	if (index === 0 && direction === 'left') {
		return null;
	}
	if (index === articles.length - 1 && direction === 'right') {
		return null;
	}
	let onClick =
		direction === 'right' ? onArticleNextClick : onArticlePreviousClick;
	const arrowLabel =
		direction === 'left' ? 'Previous Article' : 'Next Article';
	return (
		<div className={className.join(' ')}>
			<button
				onClick={onClick}
				title={arrowLabel}
				aria-label={arrowLabel}
			>
				<Icon name={`chevron-${direction}`} color={'#000'} />
			</button>
		</div>
	);
};

NavigationArrow.propTypes = {
	direction: PropTypes.oneOf(['left', 'right']),
};

function LanguageButton({ article, lang }) {
	const { onLanguageSelected } = useContext(ResponsiveModeContext);
	const { onLangChange } = useContext(AppContext);

	const languages = Object.keys(article.name);
	if (languages.length <= 1) {
		return null;
	}

	return (
		<select
			onChange={(e) => {
				console.log(`The selected language is: ${e.target.value}`);
				onLanguageSelected(e.target.value);
				onLangChange(e.target.value);
			}}
			value={lang}
		>
			{languages.map((language) => (
				<option key={language} value={language}>
					{language}
				</option>
			))}
		</select>
	);
}

LanguageButton.propTypes = {
	article: PropTypes.any,
	lang: PropTypes.string,
};
